.app {
  max-width: fit-content;
  padding-top: 32px;
  margin: 0 auto;

  &__sudoku-board {
    margin-bottom: 1rem;
  }

  &__number-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__number-buttons .number-button {
    flex: 1 1 auto;
    font-size: 24px;
    padding: 8px 0;
  }

  &__number-buttons .number-button:not(:last-of-type) {
    margin-right: 2px;

    @media screen and (min-width: 376px) {
      margin-right: 6px;
    }
  }
}

.number-button {
  background-color: transparent;
  border: 2px solid #2980b9;
  color: #2980b9;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 150ms cubic-bezier(0, 0, 0.2, 1);

  &--active {
    background-color: #2980b9;
    color: #fff;
  }
}

$normal-border: 1px solid #444;
$bold-border: 2px solid #444;

.sudoku-board {
  border-collapse: collapse;

  &__row:first-child .sudoku-board-cell {
    border-top: $bold-border;
  }

  &__row:last-child .sudoku-board-cell,
  &__row:nth-of-type(3n) .sudoku-board-cell {
    border-bottom: $bold-border;
  }
}

.sudoku-board-cell {
  border: $normal-border;
  font-size: 30px;
  box-sizing: border-box;

  &:first-child {
    border-left: $bold-border;
  }

  &:last-child {
    border-right: $bold-border;
  }

  &:nth-of-type(3n) {
    border-right: $bold-border;
  }

  &--emphasize-bg,
  &--emphasize-bg &__input {
    background: rgba(50, 50, 50, 0.05);
  }

  &--emphasize-number &__input {
    border: 3px solid blue;
    font-weight: 600;
  }

  &--error &__input {
    border: 3px solid #c0392b;
    color: #c0392b;
    font-weight: 600;
  }

  &__input {
    display: block;
    font-size: 30px;
    width: 1.25em;
    height: 1.25em;
    padding: 0;
    box-sizing: border-box;
    text-align: center;
    border: none;
    background-color: transparent;

    @media screen and (min-width: 376px) {
      width: 1.5em;
      height: 1.55em;
    }
  }
}
